<script>
import { Line } from "vue-chartjs";
import EventBus from '@/assets/js/EventBus';
export default {
  extends: Line,
  data() {
    return {
      gradient: null,
      gradient2: null,
      colors: ['#C82A54','#EF280F','#E36B2C','#E7D40A','#6DC36D','#02AC66','#23BAC4','#109DFA','#024A86','#8C4966','#222222'],

      gradientcolor1:["rgba(255, 0,0, 0.5)","rgba(0, 231, 255, 0.9)","rgba(255, 0,0, 0.5)","rgba(0, 231, 255, 0.9)","rgba(255, 0,0, 0.5)","rgba(0, 231, 255, 0.9)","rgba(255, 0,0, 0.5)","rgba(0, 231, 255, 0.9)"],
      gradientcolor2:["rgba(255, 0, 0, 0.25","rgba(0, 231, 255, 0.25)","rgba(255, 0, 0, 0.25","rgba(0, 231, 255, 0.25)","rgba(255, 0, 0, 0.25","rgba(0, 231, 255, 0.25)","rgba(255, 0, 0, 0.25","rgba(0, 231, 255, 0.9)"],
      gradientcolor3:["rgba(255, 0, 0, 0)","rgba(0, 231, 255, 0)","rgba(255, 0, 0, 0)","rgba(0, 231, 255, 0)","rgba(255, 0, 0, 0)","rgba(0, 231, 255, 0)","rgba(255, 0, 0, 0)","rgba(0, 231, 255, 0)"],
    };
  },
  mounted() {
    EventBus.$on('DataChartSale', (data) => {
       
      

        let datasets = [];
        for (let index = 0; index < data.data_chart.length; index++) {
          let element = data.data_chart[index];
         
         

          let gradient = this.$refs.canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 450);
          gradient.addColorStop(0, this.gradientcolor1[index]);
          gradient.addColorStop(0.5, this.gradientcolor2[index]);
          gradient.addColorStop(1, this.gradientcolor3[index]);

           let dataset = {
                    label: element.name,
                    borderColor: this.colors[index],
                    pointBackgroundColor: "white",
                    borderWidth: 1,
                    pointBorderColor: "white",
                    backgroundColor: gradient,
                    data: element.data
            };
            datasets.push(dataset);
        }
        this.renderChart(
            {
                labels: data.labels_chart,
                datasets: datasets
            },
            { responsive: true, maintainAspectRatio: false }
        );
    });
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");

    this.renderChart(
      {
        labels: [],
        datasets: [
          {
            label: "Reservas abandonadas",
            borderColor: "#FC2525",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "white",
            backgroundColor: this.gradient,
            data: []
          },
          {
            label: "Reservas realizadas",
            borderColor: "#05CBE1",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: this.gradient2,
            data: []
          }
        ]
      },
      { responsive: true, maintainAspectRatio: false }
    );
  }
};
</script>
