<template>
  <div>







    <b-row>
      <b-col md="10" class="bg-white">
        <ChartSales  />
      </b-col>

      <b-col md="2">
        <b-row>
          <b-col md="12">
            <b-link  :to="{ path: '/huesped/listar' }"  class="text-decoration-none" append>
            <b-card no-body class="text-center bg-primary height-card">
              <b-row class="no-gutters h-100">
                <b-col md="8" class="text-light align-self-center text-center">
                  <span>Huespedes <br> nuevos</span>
                </b-col>
                <b-col md="4" class="text-light align-self-center text-center">
                  <span class="align-middle h3">{{number_home.guests}}</span>
                </b-col>
              </b-row>
            </b-card>
            </b-link>
          </b-col>
          <b-col md="12">
            <b-link  :to="{ path: '/reserva/listar' }"  class="text-decoration-none" append>
            <b-card no-body class="text-center bg-primary height-card">
              <b-row class="no-gutters h-100">
                <b-col md="8" class="text-light align-self-center text-center">
                  <span>Nuevas <br> reservas</span>
                </b-col>
                <b-col md="4" class="text-light align-self-center text-center">
                  <span class="align-middle h3">{{number_home.reservations}}</span>
                </b-col>
              </b-row>
            </b-card>
            </b-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </div>
</template>
<style scoped>
  .height-card {
    height: 4.5rem;
  }
</style>
<script>

const axios = require("axios").default;
const je = require("json-encrypt");
import { mapState } from "vuex";

import EventBus from '@/assets/js/EventBus';
import ChartSales from './components/ChartSales'

export default {
  name: 'Dashboard',

  components: {
    ChartSales,
  },
  data () {
    return {
      number_home :{
        guests:0,
        reservations:0,
      },
      name :'',
     
    }
  },
   mounted () {
    EventBus.$on('RefreshNumberHome', () => {
      this.NumberHome();
    });
    this.NumberHome();
  },
  methods: {
    modalCPEShow,
    modalObserverVouchersShow,
    modalProductsDeliveredShow,
    modalRequirementsShow,
    modalTransformsShow,
    Permission,
    NumberHome,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
}


function modalCPEShow() {
  EventBus.$emit('ModalCPESunatShow');
}
function modalObserverVouchersShow() {
  EventBus.$emit('ModalObserverVouchersShow');
}
function modalProductsDeliveredShow() {
  EventBus.$emit('ModalProductsDeliveredShow');
}
function modalRequirementsShow() {
  EventBus.$emit('ModalRequirementsShow');
}
function modalTransformsShow() {
  EventBus.$emit('ModalTransfersShow');
}


function NumberHome() {
  let me = this;
  let url = this.url_base + "home/total-home";
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
      if (response.data.status == 200) {
        me.number_home.guests = parseFloat(response.data.guests);
        me.number_home.reservations = parseFloat(response.data.reservations);
  
        EventBus.$emit('DataChartSale',response.data.data_chart);
      }
  })
}

function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}

</script>
